import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
/* 引入最外层骨架的一级路由组件 */
// import Layout from '@/layout'

const routes = [{
    path: "/",
    name: "Layout",
    component: () =>
        import("@/layout/index"),
    children: [{
        path: "/",
        name: "echart",
        component: () =>
            import("@/views/Echart/index.vue"),
    },
    {
        path: "/SystemConfig",
        name: "/SystemConfig",
        component: () =>
            import("@/views/system/index.vue"),
    },
    {
        path: "/mall",
        name: "mall",
        component: () =>
            import("@/views/Mall/index.vue"),
        children: [{
            path: "mallList",
            name: "mallList",
            component: () =>
                import("@/views/Mall/mallList/index.vue"),
        }, {
            path: "vipMallList",
            name: "vipMallList",
            component: () =>
                import("@/views/Mall/vipMallList/index.vue"),
        },
        {
            path: "ordergoods",
            name: "ordergoods",
            component: () =>
                import("@/views/Mall/Order-goods/index.vue"),
        },
        {
            path: "classify",
            name: "classify",
            component: () =>
                import("@/views/Mall/classify/index.vue")
        },
        {
            path: "carouselList",
            name: "carouselList",
            component: () =>
                import("@/views/Mall/carouselList/index.vue")
        }
        ],
    },
    //用户管理
    {
        path: "/user",
        name: "user",
        component: () =>
            import("@/views/user/index.vue"),

        children: [
            //用户列表
            {
                path: "userlist",
                name: "userlist",
                component: () =>
                    import("@/views/user/userlist/userlist.vue"),
            },
            //用户列表
            {
                path: "Withdrawal",
                name: "Withdrawal",
                component: () =>
                    import("@/views/user/Withdrawal/Withdrawal.vue"),
            },
        ],
    },
    //分销中心
    {
        path: "/distributionCenter",
        name: "distributionCenter",
        component: () =>
            import("@/views/distributionCenter/index.vue"),

        children: [
            //佣金比例
            {
                path: "CommissionRate",
                name: "CommissionRate",
                component: () =>
                    import("@/views/distributionCenter/CommissionRate/index.vue"),
            },
        ],
    },
    // 员工管理
    {
        path: "/staffManage",
        name: "staffManage",
        component: () =>
            import("@/views/staffManage/index.vue"),
    },
    // 公告管理
    {
        path: "/NoticeManage",
        name: "NoticeManage",
        component: () =>
            import("@/views/NoticeManage/index.vue"),
    },
    // 客服管理
    {
        path: "/callCenterManage",
        name: "callCenterManage",
        component: () =>
            import("@/views/callCenterManage/index.vue"),
    },
    // 销售部申请管理
    {
        path: "/salesManage",
        name: "salesManage",
        component: () =>
            import("@/views/salesManage/index.vue"),
    },
    //代理中心
    {
        path: "/agentCenter",
        name: "agentCenter",
        component: () =>
            import("@/views/agentCenter/index.vue"),

        children: [
            //代理列表
            {
                path: "agentList",
                name: "agentList",
                component: () =>
                    import("@/views/agentCenter/agentList/index.vue"),

            },
            //代理列表
            {
                path: "agentAccount",
                name: "agentAccount",
                component: () =>
                    import("@/views/agentCenter/agentAccount/index.vue"),

            },
        ],
    },
    // 总账和数据
    {
        path: "/generalData",
        name: "generalData",
        component: () =>
            import("@/views/generalData/index.vue"),
    },
    ],
},
{
    path: "/login",
    name: "login",
    component: () =>
        import("@/views/login/index.vue"),
    meta: { requiresAuth: true }, // 这个路由需要认证
},
];

// base: process.env.BASE_URL,
const router = new VueRouter({
    mode: "history",

    routes,
});

router.beforeEach((to, from, next) => {
    let token = localStorage.getItem("token");
    if (!token && to.path !== "/login") {
        next({ path: "/login" });
    }
    return next(true);
});

export default router;